module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Heiny Domingos - Psicóloga clinica","short_name":"Heiny Domingos - Psicóloga clinica","icon":"src/images/logo-square-with-background.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d167c75390ddfda868d1fec9df780863"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"custom":[{"name":"Amsterdam Four","file":"/fonts/amsterdam-four.woff2"}],"web":[{"name":"Questrial","file":"https://fonts.googleapis.com/css2?family=Questrial&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
